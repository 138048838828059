<!-- 关于我们/企业简介-->
<template>
  <div class="recruit-agent">
    <div class="banner pc-banner">
      <img :src="bannerUrl" alt="">
    </div>
    <div class="banner-mobile">
      <img src="@/assets/images/recruit/banner-bg-mobile.png" class="gn-full">
    </div>
    <div class="bright_spot">我们拥有的资源</div>
    <div class="abstract">
      {{ bannerTitle }}
    </div>
    <div class="relation">
      <div class="title">
        <div class="line"></div>
        <div class="text">合作模式</div>
        <div class="line"></div>
      </div>
      <div class="img">
        <div class="img_left">
          <div class="img_left_img">
            <img :src="selfSupport.img" alt="">
            <div class="tip">{{ selfSupport.name }}</div>
          </div>
          <div class="text">
            <div v-for="(item, index) in selfSupport.text_item" :key="index">{{ item }}</div>
          </div>
        </div>
        <div class="img_right">
          <div class="img_right_img">
            <img :src="thirdpartnar.img" alt="">
            <div class="tip">{{ thirdpartnar.name }}</div>
          </div>
          <div class="text">
            <div v-for="(item, index) in thirdpartnar.text_item" :key="index">{{ item }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="operating_rules">
      <div class="title">
        <div class="line"></div>
        <div class="text">{{ operatingRules.introduce }}</div>
        <div class="line"></div>
      </div>
      <div class="rules_img">
        <img :src="operatingRules.img" alt="">
      </div>
    </div>
    <div class="cooperation">
      <div class="title">
        <div class="line"></div>
        <div class="text">合作条件</div>
        <div class="line"></div>
      </div>
      <div class="cooperation_item">
        <div class="cooperation_item_box">
          <img :src="cooperation1.img" alt="">
          <div>{{ cooperation1.introduce }}</div>
        </div>
        <div class="cooperation_item_box">
          <img :src="cooperation2.img" alt="">
          <div>{{ cooperation2.introduce }}</div>
        </div>
        <div class="cooperation_item_box">
          <img :src="cooperation3.img" alt="">
          <div>{{ cooperation3.introduce }}</div>
        </div>
      </div>
    </div>
    <div class="from">
      <div class="from_box">
        <div class="from_box_left">
          <div class="title">诚邀厂商合作</div>
          <div class="tip">登记信息</div>
          <div class="text">*设备送达后，我们运营团队上门培训</div>
          <div class="phone">咨询：黄经理  18925127688</div>
        </div>
        <div class="from_box_right">
          <div class="top">
            <div class="top_item">
              <div class="name">公司名称<span>*</span></div>
              <input type="text" placeholder="请输入" v-model="params.company">
            </div>
            <div class="top_item">
              <div class="name">姓名<span>*&#12288;&#12288;</span></div>
              <input type="text" placeholder="请输入" v-model="params.name">
            </div>
            <div class="top_item">
              <div class="name">电话<span>*&#12288;&#12288;</span></div>
              <input type="number" placeholder="请输入" v-model="params.phone">
            </div>
          </div>
          <button class="btn" @click="hendleSeve">立即提交</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getPublishListByType, makeAnAppointment } from '@/api/officialWebsite'

export default {
  name: 'recruit-agent',
  data () {
    return {
      bannerUrl: '',
      bannerTitle: '',
      selfSupport: {},
      thirdpartnar: {},
      operatingRules: {},
      cooperation1: {},
      cooperation2: {},
      cooperation3: {},
      params: {
        name: '',
        phone: '',
        address: '',
        company: '',
        remark: ''
      },
      timer: null
    }
  },
  created () {
    this.initData()
  },
  methods: {
    async initData () {
      const res = await getPublishListByType({ publish_type: ['15'] })
      const arr = res['15']
      arr.forEach(item => {
        if (item.name === '招聘厂商banner图') {
          this.bannerUrl = item.img
        }
        if (item.name === 'banner下的标题') {
          this.bannerTitle = item.introduce
        }
        if (item.name === '厂家自营') {
          this.selfSupport = item
          this.selfSupport.text_item = this.selfSupport.introduce.split('\n')
          console.log(this.selfSupport.text_item, 123)
        }
        if (item.name === '委托代运营') {
          this.thirdpartnar = item
          this.thirdpartnar.text_item = this.thirdpartnar.introduce.split('\n')
        }
        if (item.name === '平台运营规则') {
          this.operatingRules = item
        }
        if (item.name === '合作条件1') {
          this.cooperation1 = item
        }
        if (item.name === '合作条件2') {
          this.cooperation2 = item
        }
        if (item.name === '合作条件3') {
          this.cooperation3 = item
        }
      })
    },
    hendleSeve () {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        if (this.params.company.trim() === '') {
          this.$message.error('请填写公司名称')
          return
        }
        if (this.params.name.trim() === '') {
          this.$message.error('请填写姓名')
          return
        }
        if (this.params.phone.trim() === '') {
          this.$message.error('请填写电话号码')
          return
        }
        if (!/^[1][0-9]{10}$/.test(this.params.phone)) {
          this.$message.error('请填写正确的电话号码')
          return
        }

        makeAnAppointment(this.params)
          .then((res) => {
            this.$message.success('预约成功')
            this.params.company = ''
            this.params.name = ''
            this.params.phone = ''
          })
          .catch((error) => {
            console.error(error)
            this.$message.success(error)
          })
      }, 500)
    }
  }
}
</script>

<style scoped lang="scss">
.banner-mobile{
  display: none;
}

.recruit-agent{
  margin-top: 68px;
  color: #333333;
  .banner{
    margin: 0 auto;
    height: 453px;
    background-size: cover;
    img{
      width: 100%;
      height: 100%;
    }
  }
  .bright_spot{
    margin: 0 auto;
    margin-top: 90px;
    width: 529px;
    height: 74px;
    line-height: 74px;
    background: #db0a28;
    font-size: 60px;
    font-weight: 700;
    text-align: center;
    color: #ffffff;
  }
  .abstract{
    margin: 0 auto;
    padding: 41px 0px 79px 0px;
    width: 1440px;
    box-sizing: border-box;
    color: #333333;
    font-size: 48px;
    font-family: Source Han Sans CN, Source Han Sans CN-Bold;
    font-weight: 700;
    text-align: center;
    color: #333333;
  }
  .relation{
  margin: 0 auto;
  width: 1440px;
  .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0px 57px;
    box-sizing: border-box;
    .line{
      height: 1px;
      background-color: #DBDADA;
      flex: 1;
    }
    .text{
      margin: 0px 12px;
      font-size: 34px;
      font-weight: 700;
      color: #333333;
      line-height: 24px;
    }
  }
  .img{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .img_left{
      position: relative;
      width: 701px;
      height: 333px;
      .img_left_img{
        img{
          width: 701px;
          height: 333px;
        }
        .tip{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          font-size: 48px;
          font-weight: 700;
          color: #ffffff;
        }
      }
      .text{
        padding: 20px 30px 20px 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ededed;
        z-index: -1;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        div{
          margin-bottom: 10px;
        }
      }
    }
    .img_left:hover .text{
      z-index: 0;
    }
    .img_right{
      position: relative;
      width: 701px;
      height: 333px;
      .img_right_img{
        img{
          width: 701px;
          height: 333px;
        }
        .tip{
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%,-50%);
          font-size: 48px;
          font-weight: 700;
          color: #ffffff;
          white-space: pre-wrap;
        }
      }
      .text{
        padding: 20px 30px 20px 50px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #ededed;
        z-index: -1;
        font-size: 20px;
        font-weight: 400;
        color: #333333;
        div{
          margin-bottom: 10px;
        }
      }
    }
    .img_right:hover .text{
      z-index: 0;
    }
  }
}
  .operating_rules{
    padding-bottom: 120px;
    box-sizing: border-box;
    margin-top: 71px;
    width: 100%;
    background: #fafafa;
    .title{
      margin: 0 auto;
      width: 1440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 100px 0px 62px;
      box-sizing: border-box;
      .line{
        height: 1px;
        background-color: #DBDADA;
        flex: 1;
      }
      .text{
        margin: 0px 12px;
        font-size: 34px;
        font-weight: 700;
        color: #333333;
        line-height: 24px;
      }
    }
    .rules_img{
      margin: 0 auto;
      width: 1538px;
      height: 1162px;
      img{
        width: 1538px;
        height: 1162px;
      }
    }
  }
  .cooperation{
    margin: 0 auto;
    margin-bottom: 92px;
    width: 1440px;
    .title{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 93px 0px 56px;
    box-sizing: border-box;
    .line{
      height: 1px;
      background-color: #DBDADA;
      flex: 1;
    }
    .text{
      margin: 0px 12px;
      font-size: 34px;
      font-weight: 700;
      color: #333333;
      line-height: 24px;
    }
  }
    .cooperation_item{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .cooperation_item_box{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 435px;
        height: 307px;
        background: #f5f5f5;
        border: 2px solid #0f3a97;
        img{
          margin-bottom: 31px;
          width: auto;
          height: 95px;
        }
        div{
          width: 250px;
          font-size: 36px;
          font-weight: 700;
          text-align: center;
          color: #333333;
          line-height: 48px;
        }
      }
    }
  }
  .from{
    width: 100%;
    background-color: #f5f5f5;
    .from_box{
      display: flex;
      align-items: center;
      padding: 109px 30px 83px 60px;
      margin: 0 auto;
      width: 1440px;
      .from_box_left{
        margin-right: 30px;
        height: 574px;
        flex: 1;
        .title{
          margin-bottom: 33px;
          font-size: 85px;
          font-weight: 700;
          color: #0f3a97;
        }
        .tip{
          margin-bottom: 50px;
          width: 381px;
          height: 86px;
          background: #333333;
          font-size: 64px;
          font-weight: 700;
          color: #fefefe;
          text-align: center;
        }
        .text{
          margin-bottom: 120px;
          font-size: 35px;
          font-weight: 400;
          color: #333333;
        }
        .phone{
          font-size: 38px;
          font-weight: 700;
          color: #0c0307;
        }
      }

      .from_box_right{
        padding: 10px 35px;
        width: 650px;
        height: 574px;
        background: #ffffff;
        .top{
          width: 100%;
          .top_item{
              padding: 10px 0px;
              display: flex;
              align-items: center;
              width: 100%;
              height: 112px;
              border-bottom: 1px solid #cccccc;
              .name{
                  margin-right: 20px;
                font-size: 32px;
                font-weight: 400;
                color: #333333;
                span{
                  margin-left: 5px;
                  color: red;
                }
              }
              input{
                border: none;
                font-size: 32px;
                font-weight: 400;
                background-color: #ffffff;
              }
              input::input-placeholder{
                color:red;
              }
          }
        }
        .btn{
          margin-top: 61px;
          width: 100%;
          height: 102px;
          background: #0f3a97;
          border-radius: 10px;
          font-size: 40px;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
          line-height: 102px;
          cursor: pointer;
          border: none;
        }
      }
    }
  }
}
</style>

<style scoped lang='scss'>
// 手机版 样式
@import './media';
</style>
